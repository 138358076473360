export function getLatestBusinessesSites(channelSlug) {
    return this.get(`/api/v2/channel/${channelSlug}/latest_businesses_sites`)
}

export function getLivestreamChannels() {
    return this.get("/api/channels", {
        params: { livestreamable: 1 },
    })
}
export function getChannelBySlug(channelSlug) {
    return this.get(`/api/channels/${channelSlug}`)
}
