import { getApi } from "@/plugins/api"
import {
    getLocationFromRoute,
    searchJobsMeta,
    searchJobs,
    searchJobsSuggest,
    getJobCategories,
} from "@/services/v2/search.service"
import { getChannelBySlug } from "@/services/v2/channel.service"
import md5 from "js-md5"
import { mapBusinessResponseTimeLong } from "@/helpers/mapping"

const DEFAULT_CHANNEL_SLUG = "oabat"
export const DEFAULT_DISTANCE = 30

export const SEARCH_DURATIONS = [
    "maximal 1 Jahr",
    "maximal 2 Jahre",
    "maximal 3 Jahre",
    "beliebig",
]

export const SEARCH_GRADUATIONS = {
    none: "Keiner",
    main: "Mittelschulabschluss",
    quali: "Qualifizierender Mittelschulabschluss",
    mittel: "Mittlerer Schulabschluss",
    fos: "Fachabitur",
    abi: "Abitur",
    bachelor: "Bachelor",
    master: "Master",
    diploma: "Diploma",
    undefined: "beliebig",
}

export const SEARCH_START_YEARS = (function () {
    const initialYear = new Date().getFullYear()
    const numberOfYears = 3
    const years = Array.from(
        { length: numberOfYears },
        (_, index) => initialYear + index
    )
    return years
})()

export const SEARCH_RESPONSE_TIMES = [
    600 + 3600,
    600 + 2 * 3600,
    600 + 4 * 3600,
    600 + 8 * 3600,
    600 + 12 * 3600,
]

export class Search {
    hasNextPage = true
    meta = null
    perPage = null
    data = []
    aggregations = {}
    currentRequestJob = null
    currentRequestAggregations = null
    loading = false
    constructor({
        type,
        category,
        location,
        distance = DEFAULT_DISTANCE,
        term,
        channel = DEFAULT_CHANNEL_SLUG,
        duration,
        startYear,
        graduation,
        avgResponseTime,
        lowerSalary,
        upperSalary,
        hasTraineeship,
        hasDualStudy,
        hasGraduationProgram,
        list,
        subdomain,
        authorType,
        additionalQueries,
        perPage,
    }) {
        this.type = type
        this.channel = channel
        this.category = category
        this.location = location
        this.distance = distance ?? undefined
        this.term = term
        this.duration = duration
        this.startYear = startYear
        this.graduation = graduation
        this.avgResponseTime = avgResponseTime
        this.lowerSalary = lowerSalary
        this.upperSalary = upperSalary
        this.hasTraineeship = hasTraineeship
        this.hasDualStudy = hasDualStudy
        this.hasGraduationProgram = hasGraduationProgram
        this.list = list
        this.numberOfActiveFilters = 0
        this.subdomain = subdomain
        this.authorType = authorType
        this.error = false
        this.additionalQueries = additionalQueries
        this.perPage = perPage || undefined
        const json =
            JSON.stringify({
                channel: channel,
                type: type,
                term: term,
                category: category?.identifier,
                location: location,
                distance: distance,
                list: list,
                duration: duration,
                hasTraineeship: hasTraineeship,
                hasDualStudy: hasDualStudy,
                hasGraduationProgram: hasGraduationProgram,
            }) || ""
        this.id = md5(json)
        this.updateNumberOfActiveFilters()
    }
    updateNumberOfActiveFilters() {
        let count = 0
        if (this.term) count++
        if (this.category) count++
        if (this.distance && this.distance !== DEFAULT_DISTANCE) count++
        if (this.duration) count++
        if (this.startYear) count++
        if (this.graduation) count++
        if (this.avgResponseTime) count++
        if (this.lowerSalary) count++
        if (this.upperSalary) count++
        if (this.hasTraineeship) count++
        if (this.hasDualStudy) count++
        if (this.hasGraduationProgram) count++
        this.numberOfActiveFilters = count
    }

    get isNoIndex() {
        return (
            this?.meta?.total < 30 ||
            this?.location?.is_indexed == false ||
            this?.location?.slug === "nearby" ||
            this?.numberOfActiveFilters > 0
        )
    }
    async execute(newItemList = false) {
        const page = this.meta ? this.meta.current_page + 1 : 1
        if (page === 1 && this.type === "apprenticeship") {
            await this.executeAggregations()
        }
        if ((this.loading || !this.hasNextPage) && !newItemList) return
        this.loading = true
        const query = {
            type: this.type,
            term: this.term,
            list: this.list,
            location: this.location,
            distance: this.distance,
            channel: this.channel?.slug ?? this.channel ?? DEFAULT_CHANNEL_SLUG,
            category: this.category?.identifier,
            duration: this.duration,
            startYear: this.startYear,
            graduation: this.graduation,
            avgResponseTime: this.avgResponseTime,
            lowerSalary: this.lowerSalary,
            upperSalary: this.upperSalary,
            hasTraineeship: this.hasTraineeship,
            hasDualStudy: this.hasDualStudy,
            hasGraduationProgram: this.hasGraduationProgram,
            authorType: this.authorType,
            perPage: this.perPage,
            page,
            additionalQueries: this.additionalQueries,
        }
        try {
            if (this.currentRequestJob) {
                this.currentRequestJob.cancel()
            }
            this.currentRequestJob = getApi().run(searchJobs, query)
            const { meta, data } = await this.currentRequestJob.request
            this.currentRequestJob = null
            this.meta = meta
            if (newItemList) this.data = data
            else this.data = this.data.concat(data)
            this.hasNextPage = this.meta.current_page < this.meta.last_page
        } catch (exception) {
            console.error(exception)
            this.error = exception
        }
        this.loading = false
    }

    async executeAggregations() {
        const query = {
            type: this.type,
            term: this.term,
            location: this.location,
            distance: this.distance,
            channel: this.channel?.slug ?? this.channel ?? DEFAULT_CHANNEL_SLUG,
            authorType: this.authorType,
            category: this.category?.identifier,
            hasTraineeship: this.hasTraineeship,
            hasDualStudy: this.hasDualStudy,
            hasGraduationProgram: this.hasGraduationProgram,
        }

        try {
            if (this.currentRequestAggregations) {
                this.currentRequestAggregations.cancel()
            }
            this.currentRequestAggregations = getApi().run(
                searchJobsMeta,
                query
            )
            this.aggregations = await this.currentRequestAggregations.request
            this.currentRequestAggregations = null
        } catch (exception) {
            console.error(exception)
            this.error = exception
        }
    }

    async executeSuggestions(term) {
        try {
            const suggestParams = {
                type: this.type,
                term: term,
                channel:
                    this.channel?.slug ?? this.channel ?? DEFAULT_CHANNEL_SLUG,
                location: this.location,
                distance: this.distance,
                hasTraineeship: this.hasTraineeship,
                hasDualStudy: this.hasDualStudy,
                hasGraduationProgram: this.hasGraduationProgram,
            }

            const { data, meta } = await getApi().run(
                searchJobsSuggest,
                suggestParams
            ).request
            return { data, meta }
        } catch (exception) {
            console.error(exception)
            this.error = exception
        }
    }

    setTerm(term) {
        this.resetSearch()
        this.term = term
        this.updateNumberOfActiveFilters()
    }

    setCategory(category) {
        this.resetSearch(true)
        this.category = category
        this.updateNumberOfActiveFilters()
    }

    setDuration(durationInMonths) {
        this.resetSearch()
        this.duration = durationInMonths
        this.updateNumberOfActiveFilters()
    }

    setStartYear(startYear) {
        this.resetSearch()
        this.startYear = startYear
        this.updateNumberOfActiveFilters()
    }

    setGraduation(graduation) {
        this.resetSearch()
        this.graduation = graduation
        this.updateNumberOfActiveFilters()
    }

    setLowerSalary(lowerSalary) {
        this.resetSearch()
        this.lowerSalary = lowerSalary
        this.updateNumberOfActiveFilters()
    }

    setChannel(channel) {
        this.resetSearch()
        this.channel = channel
        this.updateNumberOfActiveFilters()
    }

    setResponseTime(time) {
        this.resetSearch()
        this.avgResponseTime = time
        this.updateNumberOfActiveFilters()
    }

    setDistance(distance) {
        this.resetSearch()
        this.distance = distance
        this.updateNumberOfActiveFilters()
    }

    setHasTraineeship(hasTraineeship) {
        this.resetSearch()
        this.hasTraineeship = hasTraineeship
        this.updateNumberOfActiveFilters()
    }

    setHasDualStudy(hasDualStudy) {
        this.resetSearch()
        this.hasDualStudy = hasDualStudy
        this.updateNumberOfActiveFilters()
    }

    setHasGraduationProgram(hasGraduationProgram) {
        this.resetSearch()
        this.hasGraduationProgram = hasGraduationProgram
        this.updateNumberOfActiveFilters()
    }

    resetSearch(ignoreAggregations = false) {
        this.data = []
        if (!ignoreAggregations) this.aggregations = {}
        this.meta = null
        this.hasNextPage = true
        this.updateNumberOfActiveFilters()
    }
    resetQuery() {
        this.resetSearch()
        this.term = undefined
        this.category = undefined
        this.duration = undefined
        this.startYear = undefined
        this.graduation = undefined
        this.lowerSalary = undefined
        this.upperSalary = undefined
        this.avgResponseTime = undefined
        this.hasTraineeship = false
        this.hasDualStudy = false
        this.hasGraduationProgram = false
        this.distance = DEFAULT_DISTANCE
        this.updateNumberOfActiveFilters()
    }
    async setLocation(location) {
        this.resetSearch()
        if (
            location &&
            !location.slug &&
            location.latitude &&
            location.longitude
        ) {
            this.location = {
                slug: "nearby",
                name: "Umgebung",
                latitude: location.latitude,
                longitude: location.longitude,
            }
        } else this.location = location
    }

    getRoute() {
        let page = this.meta?.current_page
        if (page <= 1) page = undefined
        let distanz = this.distance
        if (distanz === DEFAULT_DISTANCE) distanz = undefined

        const route = {
            name: this.getRouteName(),
            params: {
                locationSlug: this.location?.slug ?? this.channel?.slug,
                slug: this.location?.slug ?? this.channel?.slug,
            },
            query: {
                page,
                suche: this.term || undefined,
                dauer: this.duration,
                start_jahr: this.startYear,
                abschluss: this.graduation,
                min_gehalt: this.lowerSalary,
                max_gehalt: this.upperSalary,
                distanz: distanz,
                praktikum: this.hasTraineeship ? null : undefined, // valueless query param
                duales_studium: this.hasDualStudy ? null : undefined, // valueless query param
                abiturientenprogramm: this.hasGraduationProgram
                    ? null
                    : undefined, // valueless query param
                kategorie: this.category?.identifier,
                antwortzeit: this.avgResponseTime,
            },
        }
        if (route.params.locationSlug === "nearby") {
            route.query.longitude = this.location?.longitude
            route.query.latitude = this.location?.latitude
        }

        return route
    }

    getRouteName() {
        /** Case for jobFair & region page */
        if (this.subdomain) {
            let type = this.type
            if (this.type === "post") type = "news"
            if (this.type === "apprenticeship") type = "ausbildung"
            if (this.type === "business_site") type = "unternehmen"
            return `${this.subdomain}-slug-${type}`
        }
        return `ausbildung-locationSlug`
    }

    /** Needs $api from nuxt-context to execute api requests */
    async setParamsFromRoute(route) {
        // if location contains a number, it is a location (not a channel)
        const locationSlug = route.params.locationSlug
        const isLocationRoute =
            /\d/.test(locationSlug) || locationSlug === "nearby"
        const searchDistance = isLocationRoute
            ? route.query?.distanz || DEFAULT_DISTANCE
            : null
        if (route?.query) {
            this.term = route.query.suche
            this.avgResponseTime = route.query.antwortzeit
            this.distance = searchDistance
            this.upperSalary = route.query.max_gehalt
            this.lowerSalary = route.query.min_gehalt
            this.graduation = route.query.abschluss
            this.duration = route.query.dauer
            this.hasTraineeship = route.query.praktikum !== undefined
            this.hasDualStudy = route.query.duales_studium !== undefined
            this.hasGraduationProgram =
                route.query.abiturientenprogramm !== undefined
            this.startYear = route.query.start_jahr
            if (route.query?.kategorie) {
                const categories = await getApi().run(getJobCategories)
                this.category = categories.find((category) => {
                    return category.identifier === route.query.kategorie
                })
            }
        }
        if (isLocationRoute) {
            if (locationSlug === "nearby") {
                this.location = {
                    slug: "nearby",
                    name: "Umgebung",
                    latitude: route.query.latitude,
                    longitude: route.query.longitude,
                }
            } else {
                this.location = await getApi().run(getLocationFromRoute, route)
                    .request
            }
        } else {
            this.channel = await getApi().run(
                getChannelBySlug,
                route.params.locationSlug || route.params.slug
            ).request
        }
        this.updateNumberOfActiveFilters()
    }
}

/** requires context for $config & $route.
 * Execute using getHead.call(this, search)
 * */
export function getHead(search) {
    const title = getHeadTitle(search)
    const description = getHeadDescription(search)

    return {
        title,
        link: [getCanonicalLink.call(this, search)],
        script: [],
        meta: [
            {
                hid: "description",
                name: "description",
                content: description,
            },
            {
                hid: "og:title",
                name: "og:title",
                content: title,
            },
            {
                hid: "og:description",
                name: "og:description",
                content: description,
            },
            {
                hid: "robots",
                name: "robots",
                content: search.isNoIndex ? "noindex, nofollow" : undefined,
            },
        ],
    }
}

function getHeadTitle(search) {
    let title = ""
    if (search.hasTraineeship) title += `Praktikum `
    else title += `Ausbildung`
    const location = getLocation(search)
    if (location) title += ` ${location}`
    if (search.startYear) title += ` ${search.startYear}`
    else title += ` ${getYearString()}`
    if (search.category) title += ` im Bereich ${search.category.name}`
    if (search.hasTraineeship) title += ` | Freie Schülerpratktika`
    return title
}

function getYearString() {
    let year = new Date().getFullYear()
    const currentDate = new Date()
    if (currentDate.getMonth() > 7) {
        year += 1
    }
    return ` ${year} / ${year + 1}`
}

function getLocation(search) {
    const area = search?.location ?? search?.channel
    if (!area) return ""
    if (area.name !== "Umgebung") return area.name
    return "der Umgebung"
}

function getHeadDescription(search) {
    let description = ""
    if (search.hasTraineeship)
        description += `Finde schnell einen Praktikumsplatz`
    else description += "Entdecke schnell freie Ausbildungsplätze"
    if (search.category) description += ` im Bereich ${search.category.name}`
    if (search.startYear) description += ` ab dem Jahr ${search.startYear}`
    if (search.hasTraineeship)
        description += " für Schülerpraktika und zur Berufsorientierung"
    else
        description += " und schick online deine Bewerbung an die besten Firmen"
    const location = getLocation(search)
    if (location) description += ` in ${location}`
    if (search.distance && search.distance !== DEFAULT_DISTANCE)
        description += ` und ${search.distance}km Umkreis.`
    else description += "."
    if (search.lowerSalary) {
        description += ` Dein Gehalt wird über ${search.lowerSalary}€ liegen.`
    }
    if (search.avgResponseTime)
        description += ` Durchschnittliche Antwortzeit der Betriebe ist ${mapBusinessResponseTimeLong(
            search.avgResponseTime
        )} Stunden.`
    return description
}

function getCanonicalLink(search) {
    const { name, params, query } = search.getRoute()
    const canonicalPath = this.$router.resolve({
        name,
        params,
        query: {
            praktikum: query.praktikum,
        },
    }).href

    return {
        rel: "canonical",
        href: `${this.$config.baseURL}${canonicalPath}`,
    }
}
