export function getLocationFromRoute(route) {
    const slug = route.params.locationSlug
    return getLocationFromSlug.call(this, slug)
}

export function getLocationFromSlug(slug) {
    const ZIP_REGEX = /zip-(.*)-([0-9]+)$/
    const DEFAULT_REGEX = /-([0-9]+)$/

    if (ZIP_REGEX.test(slug)) {
        return this.get(`/api/v2/locations/zip/${slug.match(ZIP_REGEX)[2]}`)
    } else if (DEFAULT_REGEX.test(slug)) {
        slug = slug.match(DEFAULT_REGEX)[1]
    }
    return this.get(`/api/v2/locations/${slug}`)
}

export function getJobCategories() {
    return this.get("/api/job_categories")
}

export function searchJobs({
    type,
    term,
    channel,
    location,
    distance,
    category,
    list,
    duration,
    startYear,
    graduation,
    upperSalary,
    lowerSalary,
    avgResponseTime,
    exclude_job_slug,
    business_slug,
    page,
    authorType,
    onlyPro,
    perPage,
    hasTraineeship,
    hasDualStudy,
    hasGraduationProgram,
    additionalQueries,
}) {
    return this.get(
        `/api/v2/search/${type}`,
        {
            term: term,
            channel: channel,
            category: category,
            list: list,
            distance: distance,
            latitude: location?.latitude,
            longitude: location?.longitude,
            duration: duration,
            start_year: startYear,
            graduation: graduation,
            avg_response_time: avgResponseTime,
            exclude_job_slug: exclude_job_slug,
            business_slug: business_slug,
            lower_salary: lowerSalary,
            upper_salary: upperSalary,
            has_traineeship: hasTraineeship ? 1 : 0,
            has_dual_study: hasDualStudy ? 1 : 0,
            has_graduation_program: hasGraduationProgram ? 1 : 0,
            only_pro: onlyPro,
            page: page,
            per_page: perPage,
            author_type: authorType,
            ...additionalQueries,
        },
        {
            rawResponse: true,
        }
    )
}

export function searchJobsMeta({
    type,
    term,
    channel,
    location,
    distance,
    hasTraineeship,
    hasDualStudy,
    hasGraduationProgram,
    category,
    authorType,
}) {
    return this.get(`/api/v2/search/${type}/meta`, {
        term: term,
        channel: channel,
        category: category,
        distance: distance,
        has_traineeship: hasTraineeship ? 1 : 0,
        has_dual_study: hasDualStudy ? 1 : 0,
        has_graduation_program: hasGraduationProgram ? 1 : 0,
        latitude: location?.latitude,
        longitude: location?.longitude,
        author_type: authorType,
    })
}

export function searchJobsSuggest({
    type,
    term,
    channel,
    location,
    distance,
    hasTraineeship,
    hasDualStudy,
    hasGraduationProgram,
}) {
    return this.get(
        `/api/v2/search/${type}/suggest`,
        {
            term: term,
            channel: channel,
            distance: distance,
            latitude: location?.latitude,
            longitude: location?.longitude,
            has_traineeship: hasTraineeship ? 1 : 0,
            has_dual_study: hasDualStudy ? 1 : 0,
            has_graduation_program: hasGraduationProgram ? 1 : 0,
        },
        {
            rawResponse: true,
        }
    )
}
